import React, { useEffect, useState, useRef } from "react";
import TrafficRed from "../assets/traffic-red.png";
import TrafficYellow from "../assets/traffic-yellow.png";
import TrafficGreen from "../assets/traffic-green.png";

export default function TrafficAnimation(props) {
    const INITIAL_INDEX = 1;
    const TARGET_INDEX = 3;
    const interval = useRef();
    const [index, setIndex] = useState(INITIAL_INDEX);

    const updateIndex = () => {
      //  console.log(index);
        if (index === TARGET_INDEX) {
            setIndex(INITIAL_INDEX);
        } else {
            setIndex(index+1);
        }
    }

    useEffect(() => {
        function handelInterval() {
            interval.current = setInterval(() => {
                updateIndex();              
              }, 500);
        }
      /*  if (index >= TARGET_INDEX && interval.current) {
            clearInterval(interval.current);
        }      
        if (index === INITIAL_INDEX) {*/
            clearInterval(interval.current);
            handelInterval();
      //  }
     /*   const timer = setInterval(() => {
            updateIndex();
        }, 1000);
        return () => clearInterval(timer);*/
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        }
    }, [index]);

    var image = TrafficRed;
    switch(index) {
        case 1:
            image = TrafficRed;
            break;
        case 2:
            image = TrafficYellow;
            break;
        case 3:
            image = TrafficGreen;
            break;
        default:
            image = TrafficRed;
    }    

    return (
        <div style={{ width: 557, height: 248, overflow: "hidden",position:'absolute', top:'70px', left:'160px'} }>
            <img src={image} style={{ width: '100%' }} />
        </div>
    )
}