import React from "react";
import TrafficRed from "../assets/traffic-red.png";
import TrafficYellow from "../assets/traffic-yellow.png";
import TrafficGreen from "../assets/traffic-green.png";

const TrafficLight = (props) => {
    const { color } = props;

    const getTrafficlightImage = (color) => {
        switch (color) {
            case 1:
                return TrafficRed;
            case 2:
                return TrafficYellow;
            case 3:
                return TrafficGreen;
            default:
                return TrafficRed;
        }
    }
    return(
        <div className="trafficLight">
            <img src={getTrafficlightImage(color)} style={{width:'90%'}} />
        </div>
    )
}

export default TrafficLight;