import React from "react";
import ButtonText from '../components/ButtonText';
import BackSign from '../assets/back-sign.png';

export default function BackButton(props) {
    const { text, disabled, callback } = props;
    return (
        <ButtonText className="nav thinkplan" disabled={disabled} onClick={callback}>
            <img src={BackSign} style={{height:'35px', marginRight:'5px'}}/>{text}
        </ButtonText> 
    )
}