import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {useDrop, useDrag} from 'react-dnd';
import AnswerBubbleDrag from '../components/AnswerBubbleDrag';
import { changeBg, setMoveIndexes, removeFromCategory, deselectBubble, chooseSolution, insertDummyBubble, removeTempBubble, reorderCategory } from "../redux/actions/";
import ModalPortal from '../components/ModalYesNo';
import KindOfHelpfulTitle from '../assets/kindofhelpful-title.png'; 
import NotHelpfulTitle from '../assets/nothelpful-title.png'; 
import HelpfulTitle from '../assets/helpful-title.png'; 

const CategorySection = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [answersArr, setAnswersArr] = useState([]);
    const [modalInfo, setModalInfo] = useState(false);
    const [solution, setSolution] = useState(null);
    const [destInd, setDestInd] = useState(-1);
    const [dummy, setDummy] = useState(false);
    const [over, setOver] = useState(false);

    const cats = useSelector((state => state.cats));
 /*   const cat2 = useSelector((state => state.cat2));
    const cat3 = useSelector((state => state.cat3));*/
    const {color, title, onDrop, unDrop, qIndex, disabled, chooseEnabled, onCatBubbleDbClick} = props;

 /*   useEffect(() => {
      //  console.log(qIndex);
        var arr = cats[qIndex];
      //  console.log(arr);
        var tmpArr = [];
        for (var i=0;i<arr.length;i++) {
           /// tmpArr.push({text: arr[i].text, category: arr[i].category, index:i});
            tmpArr.push({text: arr[i].text, category: arr[i].category});
        }
        //console.log("setAnswersArr", qIndex);
        setAnswersArr(tmpArr);
    },[cats]);*/

    useEffect(() => {
        dispatch(changeBg('white'));
    },[]);

    const moveListItem = useCallback(
        (oldIndex, newIndex, sourceCat, index) => {
          //  console.log("SS CAT", oldIndex, newIndex, sourceCat, index);
            if (sourceCat == qIndex) {
              //  console.log("SS CAT A", sourceCat, oldIndex, newIndex);
               /// console.log(sourceCat, qIndex, oldIndex, newIndex);
                /// !!!
              //  if (oldIndex != newIndex) {


              ///  dispatch(insertDummyBubble(qIndex, newIndex));
              ///  setDummy(true);

                ///dispatch(reorderCategory(sourceCat, oldIndex, newIndex));
             //   console.log("SS CAT A dispatch move",sourceCat, oldIndex, newIndex);
                dispatch(setMoveIndexes(sourceCat, oldIndex, newIndex, index));
                dispatch(insertDummyBubble(sourceCat, newIndex));
                setDummy(true);


              //  }
            } else {
              //  console.log("SS CAT B");
                dispatch(insertDummyBubble(qIndex, newIndex));
                setDummy(true);
            }
            if (isActive) {
                setDestInd(qIndex);
            } else {
              //  setDestInd(index);
            }
        },[cats]
    );

    const setDrop = (item, monitor) => {
      //  console.log("setDrop", item.placeInd);
        //setTextDrop(item.option);
       // if (qIndex != item.category) {
            onDrop(qIndex, item, destInd, item.placeInd);
        /* setDataDraging({
                option: item.option,
                index: item.index,
            });*/
       // }
        setDestInd(-1);
    };

    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'box',
        hover: (item, monitor) => {
            if (!over) {
               /// console.log("Hover", qIndex);
                setOver(true);
            }
        },
        drop: (item, monitor) => setDrop(item, monitor),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;

    if (!isActive && dummy) {
        ///setDummy(false);
       /// dispatch(removeTempBubble(qIndex));
    }
    
    const onClose = (index) => {
       // console.log("onClose", index);
       // dispatch(removeFromCategory(qIndex, index))
       setModalInfo('Are you sure you want to delete this solution?');
       setSolution({category:qIndex, index:index});
    }

    const onSelectSolution = (category, index) => {
        if ( chooseEnabled) {
            setModalInfo('Are you sure you want to select this solution?');
            setSolution({category:category, index:index});
        }
    }

    const onBubbleClick = (index) => {
       // console.log("onBubbleClick", index);
        onCatBubbleDbClick(qIndex, index);
       // setShowInput({show:true, index:index});
    }
///console.log(over,isOver);
    if ((over)&&(!isOver)) {
        setOver(false);
    }

    var catColor = color;
    if (over) {
        catColor = color+" selected";
    }

    var bodyColor = color;
    if (over) {
        bodyColor = color+" selected";
    }
   // console.log(catColor);
    var opacity = disabled ? 0.4 : 1;
    var TitleImage = NotHelpfulTitle;
    if (title == "KindOfHelpful") {
        TitleImage = KindOfHelpfulTitle;
    } else if (title == "Helpful") {
        TitleImage = HelpfulTitle;
    }

    return (
        <>
        <div className="categorySection"  style={{opacity}} ref={drop}>
            <div className={`title ${catColor}`}>
                <img src={TitleImage} style={{margin: 'auto', maxWidth: '100%', marginTop:'15px', marginBottom:'10px'}}/>
            </div>
            <div className={`body ${bodyColor}`}>
                {cats[qIndex].map((obj, index) => {
                    return <AnswerBubbleDrag text={obj.text} disabled={disabled} index={index} key={obj.id} category={obj.category} selectSolution={onSelectSolution} chooseEnabled={chooseEnabled} onBubbleClick={onBubbleClick} moveListItem={moveListItem}></AnswerBubbleDrag>
                })}
            </div>
        </div>
        {modalInfo !== false && (
                <ModalPortal 
                    onNo={() => {
                        setModalInfo(false);
                        ///dispatch(deselectBubble(solution.category, solution.index));
                       // dispatch(deselectBubble());
                        //setDelindex(null);
                    }} 
                    onYes={() => {
                       // dispatch(removeAnswer(delindex)); 
                        setModalInfo(false);
                        dispatch(removeFromCategory(solution.category, solution.index));
                       // dispatch(chooseSolution(solution.category, solution.index));
                       // navigate('/try');
                    }}
                >
                    {modalInfo}
                </ModalPortal>
                )}
        </>
    )
}

export default CategorySection;