import React, {useEffect, useState, useRef, useLayoutEffect } from "react";
import ButtonText from '../components/ButtonText';
import {useDrop, useDrag} from 'react-dnd';
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { selectBubble, deselectBubble, removeTempBubble } from "../redux/actions/";

const AnswerBubbleDrag = ({text, disabled, index, category, onClose, selectSolution, chooseEnabled, onBubbleClick, moveListItem }) => {
    const dispatch = useDispatch();
    const [clicks, setClicks] = useState(0);
    const [cl, setCl] = useState("bubble");
    const [waitingClick, setWaitingClick] = useState(null);
    const [lastClick, setLastClick] = useState(0);
    const [dragIndex, setDragIndex] = useState(-1);
    const [placeInd, setPlaceInd] = useState(-1);
   // const [width, setWidth] = useState(0);
  //  const [height, setHeight] = useState(0);
    const cats = useSelector(state => state.cats);

 /*   useLayoutEffect(() => {
       // setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);
    });
    console.log(height);*/
    const [{isDragging}, dragRef, preview] = useDrag({
        type: 'box',
        item: {index, category, text},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: monitor => 
            {
               // console.log('END DRAG');
               /// setPlaceInd(-1);
               /// setDragIndex(-1);
                dispatch(removeTempBubble());
            }
        ,
    });

    useEffect(() => {
          preview(getEmptyImage());
      }, [preview]);
    
    const [{canDrop, isOver}, dropRef] = useDrop({
        accept: 'box',
/*        drop(item, monitor) {
           // const didDrop = monitor.didDrop();
           // console.log("didDrop");
        },*/
        hover: (item, monitor) => {
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
            const clientCat = category;
            const hoverIndex = index;
            if (hoverActualY < hoverMiddleY) {
              //  console.log("pos above", hoverIndex, dragIndex, placeInd, item.index);
            }    
            if (hoverActualY >= hoverMiddleY) {
              //  console.log("pos below", hoverIndex, dragIndex, placeInd, item.index);
            }    
            const dragCat = item.category;
            if ((clientCat >=0)&&(clientCat != dragCat)) {
              //  console.log("HOVER1",hoverBoundingRect);
                if ((dragCat == -1)&&(dragIndex == -1)) {
                  ///  console.log("dragCat", clientCat, cats[clientCat].length);
                    setDragIndex(cats[clientCat].length);
                }    
                if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) {
                   /// setPlaceInd(-1);
                   if (clientCat == -1) {
                      return
                   } else {

                   }
                } else if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) {
                  /// setPlaceInd(-1);
                   if (clientCat == -1) {
                     return
                   } else {

                   }
                } else if (placeInd != hoverIndex) {
                   setPlaceInd(hoverIndex);
                }
              ///  console.log(dragIndex, placeInd, hoverIndex);
                if (placeInd >= 0) {
                //    dispatch(removeTempBubble());
                    if (cats[clientCat][placeInd]) {
                        if (cats[clientCat][placeInd].text.length > 0 ) {
                          //  console.log("moveListItem",dragIndex, placeInd);
                          //  console.log(cats[clientCat][placeInd].text.length);
                            item.placeInd = placeInd;
                            moveListItem(dragIndex, placeInd, dragCat, item.index);
                            setDragIndex(placeInd);
                        }
                    }
                } else {
                }
            } else if ((clientCat >=0)&&(clientCat == dragCat)) {
             //   console.log("HOVER2",dragIndex, placeInd, item.index, item.text);
                if (dragIndex != item.index || dragIndex < 0) {
                     setDragIndex(item.index);
                   /// setPlaceInd(-1);
                   ///setPlaceInd(dragIndex);
                   setPlaceInd(hoverIndex);
              //     console.log("HOVER setDragIndex Top", dragIndex, placeInd,item.index);
                    ///moveListItem(dragIndex, placeInd, dragCat);
                }
                if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY && placeInd >= 0) {
                 //   console.log("HOVER2_1");
                    return;
                } else if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY && placeInd >= 0) {
                 //   console.log("HOVER2_2");
                    return;
                } else if (placeInd != hoverIndex) {
                  //  console.log("HOVER2_3");
                    setPlaceInd(hoverIndex);
                }
                if ((dragIndex != placeInd)&&(dragIndex >= 0)&&(placeInd >= 0)) {
                 //   console.log("HOVER2_4");
                  ///  console.log("moveListItem",dragIndex, placeInd);
                     moveListItem(dragIndex, placeInd, dragCat, item.index);
                    /// item.index = placeInd;
                    setDragIndex(placeInd);
                }
            }
       },
        collect: monitor => ({
           // isOver: monitor.isOver(),
           // canDrop: monitor.canDrop(),
        }),
    });
    
    const setDrop = () => {
      //  console.log("setDrop");
    }

    useEffect(() => {
        if (category >=0) {
            if (cats[category][index]) {
                if (cats[category][index].selected) {
                    //setCl("bubble green");
                    setCl("answerBubbleGreen");
                    setClicks(1);
                } else {
                    setCl("bubble");
                    setClicks(0);
                }
            }
        }
    }, [cats]);

    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref))

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          //  console.log(clicks);
            event.preventDefault();
            if (clicks == 1) {
                if (ref.current && !ref.current.contains(event.target)) {
                    ///alert("You clicked outside of me!");
                    if (cats[category][index]) {
                        if (cats[category][index].selected) {
                            dispatch(deselectBubble(category, index));
                        }
                    }
                }
            }
        }
        // Bind the event listener
           document.addEventListener("mouseup", handleClickOutside);
            return () => {
        // Unbind the event listener on clean up
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [dragDropRef, clicks]);

    const processClick = (e) => {
        if(lastClick&&e.timeStamp - lastClick < 250 && waitingClick) {
          setLastClick(0);
          clearTimeout(waitingClick);
          setWaitingClick(null);
         // console.log('double click')
          onBubbleClick(index);
        } else {
            setLastClick(e.timeStamp);
            setWaitingClick(setTimeout(()=>{
            setWaitingClick(null);
            }, 251))
          //  console.log('single click');
            onClick();
          }          
    }
    
    const onClick = () => {
       // console.log("category",category);
        if ((category >= 0) && (chooseEnabled)) {
            if (clicks < 2) {
                if (clicks == 0) {
                    if (!disabled) {
                        setClicks(clicks+1);
                        dispatch(selectBubble(category, index));
                    }
                }
                if (clicks == 1) {
                    dispatch(deselectBubble(category, index));
                   /// selectSolution(category, index);
                }
            }
        }
       // console.log("clicks",clicks);
    }

    const onCloseClick = () => {
      //  console.log(index);
        onClose(index, category);
    }

    var btnClose = null;
    if (onClose && (text.length > 0)) {
        btnClose =  <ButtonText className="close" onClick={onCloseClick}>
                        <b>x</b> 
                    </ButtonText>
    }
 /*   var cl = "bubble";
    //console.log(clicks);
    if (clicks == 1) {
        cl = "bubble green";
    } */
    if ((text.length == 0)&&(cl!="ghost")) {
        setCl("ghost");
    }
    // Join the 2 refs together into one (both draggable and can be dropped on)

    if (isDragging) {
       /// console.log("Draging Index", index);
    }

    if (disabled) {
        var opacity = isDragging ? 0.01 : 0.4;
        var height = isDragging ? 0 : "";
    } else {
        opacity = isDragging ? 0.01 : 1;
        height = isDragging ? 0 : "";
    }
    /// opacity = 1;
    return (
        <div className="answerBubble" ref={dragDropRef} style={{opacity, height}}>
            <div className={cl} onClick={processClick} >
                {text}
            </div>
            {btnClose}
        </div>
    )
}

export default AnswerBubbleDrag;