import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setTransParams } from '../redux/actions/';
// import Bg from '../assets/bg.png';
import Bg from '../assets/Blue_Background.png';
import BgPaper from '../assets/bg-paper.png';

function LayoutGeneral({ children }) {
    const appw = 1674;
    const apph = 900;
    const navigate = useNavigate();
    const BgString = useSelector((state => state.bg));
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [ratio, setRatio] = useState(1);
    const [wofs, setWofs] = useState(0);
    const [hofs, setHofs] = useState(0);
    const dispatch = useDispatch();
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        navigate('/start');
    }, [])

    const handleResize = (e) => {
      //  console.log("window resize");
        let ww = window.innerWidth;
        let hh = window.innerHeight;
      //  console.log("ratio window resize", hh, ww);
        setRatio(1);
        if (ww >= hh) {
            let rr = hh/apph;
            if (appw*rr >=ww) {
                let rr = ww/appw;
                setNewParams(rr, 0, (hh-apph*rr)/2);
            } else {
                let rr = hh/apph;
                setNewParams(rr, (ww-appw*rr)/2, 0);
            }
          //  console.log("ratio1", rr,wofs, hofs);
          //  console.log("ratio1", ww*(hh/apph),(ww-appw*rr)/2);
        } else {
            let rr = hh/apph;
            if (apph*rr > hh) {
                let rr = hh/apph;
                setNewParams(rr, (ww-appw*rr)/2, 0);
            } else {
                let rr = ww/appw;
                setNewParams(rr, 0, (hh-apph*rr)/2);
            }
          //  console.log("ratio1", rr,wofs, hofs);
          ///  console.log("ratio3", rr, hh*rr,ww*rr);
        }
        ///{transform: `scale(${ratio})`}
    }

    const setNewParams = (r,w,h) => {
        setRatio(r);
        setWofs(w);
        setHofs(h);
        dispatch(setTransParams(r,w,h));
    }

    var BgImage = Bg;
    if (BgString == 'white') {
        BgImage = BgPaper;
    }
    return(
             <div id="wrapper" style={{position:'absolute',top:`${hofs}px`, left:`${wofs}px`,transform: `scale(${ratio},${ratio})`}}>
                <div id="main" style={{backgroundImage:`url(${BgImage})`}}>
                    {children}
                </div>
            </div>
    )
}

export default LayoutGeneral;