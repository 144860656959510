import { 
    SET_DILEMMA, 
    ADD_ANSWER, 
    REMOVE_ANSWER, 
    CHANGE_ANSWER, 
    ADD_TO_CATEGORY_FROM_TOP, 
    REPLACE_CATEGORY, 
    REMOVE_FROM_CATEGORY,
    SELECT_BUBBLE,
    DESELECT_BUBBLE,
    CHOOSE_SOULTION,
    NEW_DILEMMA,
    INSERT_DUMMY_BUBBLE,
    REMOVE_TEMP_BUBBLE,
    REORDER_CATEGORY,
    CHANGE_BUBBLE_XY,
    CLEAR_SOLUTION,
    CHANGE_BG,
    SET_CHOOSE_ENABLED,
    SET_MOVE_INDEXES,
    SET_TRANS_PARAMS,
    SET_ANSWERS_POS
} from '../types';

export const setDilemma = (text) => {
  //  console.log('Action setDilemma');
    return dispatch => {
        dispatch({
            type: SET_DILEMMA,
            text: text
        });
    };
}

export const addAnswer = (text, xx, yy, rind, pind) => {
  //  console.log('Action addAnswer');
    return dispatch => {
        dispatch({
            type: ADD_ANSWER,
            text,
            xx,
            yy,
            rind,
            pind
        });
    };
}

export const removeAnswer = (index) => {
  //  console.log('Action removeAnswer');
    return dispatch => {
        dispatch({
            type: REMOVE_ANSWER,
            index: index
        });
    };
}

export const changeAnswer = (index, text, category) => {
  //  console.log('Action changeAnswer');
    return dispatch => {
        dispatch({
            type: CHANGE_ANSWER,
            index: index,
            text: text,
            src: category
        });
    };
}

export const addToCategoryFromTop = (srcindex, dest, newIndex) => {
  //  console.log('Action addToCategoryFromTop');
    return dispatch => {
        dispatch({
            type: ADD_TO_CATEGORY_FROM_TOP,
            srcindex: srcindex,
            dest: dest,
            newIndex: newIndex
        });
    };
}

export const replaceCategory = (src, dest, index, newIndex) => {
  //  console.log('Action replaceCategory',src, dest, index, newIndex);
    return dispatch => {
        dispatch({
            type: REPLACE_CATEGORY,
            src: src,
            dest: dest,
            index: index,
            newIndex: newIndex
        });
    }
}

export const removeFromCategory = (src, index) => {
  //  console.log('Action replaceCategory');
    return dispatch => {
        dispatch({
            type: REMOVE_FROM_CATEGORY,
            src: src,
            index: index
        });
    }
}

export const selectBubble = (src, index) => {
 //   console.log('Action selectBubble');
    return dispatch => {
        dispatch({
            type: SELECT_BUBBLE,
            src: src,
            index: index
        });
    }
}

export const deselectBubble = (src, index) => {
  //  console.log('Action deselectBubble');
    return dispatch => {
        dispatch({
            type: DESELECT_BUBBLE,
            src: src,
            index: index
        });
    }
}

export const chooseSolution = (src, index) => {
  //  console.log('Action chooseSolution');
    return dispatch => {
        dispatch({
            type: CHOOSE_SOULTION,
            src: src,
            index: index
        });
    }
}

export const newDilemma = () => {
  //  console.log('Action setSolution');
    return dispatch => {
        dispatch({
            type: NEW_DILEMMA,
        });
    }
}

export const insertDummyBubble = (src, index) => {
  //  console.log('Action insertDummyBubble');
    return dispatch => {
        dispatch({
            type: INSERT_DUMMY_BUBBLE,
            src: src,
            index: index
        });
    }
}

export const removeTempBubble = (src) => {
 //   console.log('Action removeTempBubble');
    return dispatch => {
        dispatch({
            type: REMOVE_TEMP_BUBBLE,
            src:src
        });
    }
}

export const reorderCategory = (src, oldIndex, newIndex, index) => {
 //   console.log('Action reorderCategory');
    return dispatch => {
        dispatch({
            type: REORDER_CATEGORY,
            src:src,
            oldIndex: oldIndex,
            newIndex: newIndex,
            index: index
        });
    }

}

export const changeBubbleXY = (index, xx, yy) => {
 //   console.log('Action changeBubbleXY');
    return dispatch => {
        dispatch({
            type: CHANGE_BUBBLE_XY,
            index,
            xx,
            yy
        });
    }
}

export const clearSolution = () => {
  //  console.log('Action clearSolution');
    return dispatch => {
        dispatch({
            type: CLEAR_SOLUTION,
        });
    }
}

export const changeBg = (bg) => {
 //   console.log("changeBg");
    return dispatch => {
        dispatch({
            type: CHANGE_BG,
            bg: bg,
        });
    }
}

export const setChooseEnebled = (fl) => {
  //  console.log("setChooseEnebled")
    return dispatch => {
        dispatch({
            type: SET_CHOOSE_ENABLED,
            fl: fl,
        });
    }
}

export const setMoveIndexes = (sourceCat,oldIndex,newIndex, index) => {
  //  console.log("setMoveIndexes")
    return dispatch => {
        dispatch({
            type: SET_MOVE_INDEXES,
            sourceCat: sourceCat, 
            oldIndex: oldIndex, 
            newIndex: newIndex,
            index: index
        });
    }
}

export const setTransParams = (r,w,h) => {
  //  console.log("setTransParams")
    return dispatch => {
        dispatch({
            type: SET_TRANS_PARAMS,
            obj: {r:r,w:w,h:h}, 
        });
    }
}

export const setAnswersPos = (ind, maxZ, xx, yy) => {
    console.log("setAnswersPos",ind,maxZ);
    return dispatch => {
        dispatch({
            type: SET_ANSWERS_POS,
            ind,
            xx,
            yy,
            maxZ
        })
    }
}
