import React, { useState, useEffect } from "react";
import { useLocation, useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import ButtonImage from '../components/ButtonImage';
import StartTitleImg from '../assets/start-title.png';
import StartBtnImg from '../assets/start-button.png';
import IntroLogo from '../assets/intro_screen_logo.png';
import TrafficAnimation from '../components/TrafficAnimation';
import { changeBg } from '../redux/actions';

const StartPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNew = () => {
       // console.log("newClicked");
        navigate('/recognize');
    }

    useEffect(() => {
        dispatch(changeBg('yellow'));
    },[])

    return (
        <div className="StartPage">
            <div className="StartText">
                <img src={StartTitleImg}/>
               <TrafficAnimation style={{position:'relative', top:'70px', left:'240px'}}/>
            </div>
                {/*
                <ButtonText className="start" disabled={true}>
                    Open
                </ButtonText> 
                <ButtonText className="start" onClick={handleNew} >
                   Start
                </ButtonText> 
            */}
                <ButtonImage className="start" onClick={handleNew} >
                    <img src={StartBtnImg} style={{width: '100%'}} />
                </ButtonImage> 
                <div className="IntroLogo">
                    <img src={IntroLogo} />
                </div>
       </div>
    )
}

export default StartPage;