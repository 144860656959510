import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate  } from 'react-router-dom';
import TrafficLight from '../components/TrafficLight';
import BackButton from '../components/BackButton';
import NextButton from '../components/NextButton';
import { setChooseEnebled, setMoveIndexes, reorderCategory, addToCategoryFromTop,  deselectBubble, replaceCategory, changeAnswer, clearSolution, chooseSolution} from "../redux/actions/";
import CategorySection from '../components/CategorySection'
import AnswerBubbleDrag from '../components/AnswerBubbleDrag';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getEmptyImage } from "react-dnd-html5-backend";
import CustomDragLayer from '../components/CustomDragLayer';
import AnswerInputPage from './AnswerInputPage';
import ModalPortal from '../components/ModalYesNo';
import ThinkplanTitle1 from '../assets/thinkplan-title1.png';
import ThinkplanTitle4 from '../assets/thinkplan-title4.png';
import ThinkplanTitle5 from '../assets/thinkplan-title5.png';

const OrganizePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showInput, setShowInput] = useState({show:false, index:null});
    const [showCategoryInput, setShowCategoryInput] = useState({show:false, index:null});
    const [disableNext, setDisableNext] = useState(true);

   /// const [chooseEnabled, setChooseEnabled] = useState(false);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const [modalInfo, setModalInfo] = useState(false);
    const [solution, setSolution] = useState(null);
    const answersArr = useSelector(state => state.answers);
    const cats = useSelector(state => state.cats);
    const dilemma = useSelector(state => state.dilemma);
    const chooseEnabled = useSelector(state => state.chooseEnabled);

    const sourceCat = useSelector(state => state.sourceCat);
    const oldIndex = useSelector(state => state.oldIndex);
    const destNewIndex = useSelector(state => state.newIndex);

    const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints);

    const backendOptions = {
        enableTouchEvents: true,
        enableMouseEvents: true,
        enableHoverOutsideTarget: true,
    };

    useEffect(() => {
        setCurrentAnswer(answersArr[0]);
    }, [])

    useEffect(() => {
        if (answersArr[0]) {
            setCurrentAnswer(answersArr[0]);
        } else {
            setCurrentAnswer(null);
        }
    }, [answersArr])

    useEffect(() => {
     //  console.log("useEffect", cats);
            var fl = false;
            var haveUseful = false;
            var selected = false;
            for (var i=0;i<cats.length;i++) {
                if (cats[i].length > 0) {
                    fl = true;
                    for (var j=0;j<cats[i].length;j++) {
                        if (cats[i][j].category > 0) {
                            haveUseful = true;
                        }
                        if (cats[i][j].selected) {
                            selected = true;
                        }
                    }
                    if (fl &&  haveUseful) {
                       break;
                    }                    
                }
            }
            if (fl) {
                if (chooseEnabled)  {
                  //  console.log(dilemma.solution, haveUseful);
                    if (dilemma.solution) {
                        if (haveUseful) {
                           // console.log(dilemma.solution);
                            if (dilemma.solution.selected) {
                                setDisableNext(false);
                            } else {
                                setDisableNext(true);
                            }
                        } else {
                            setDisableNext(true);
                        }
                       // setSolution({category:dilemma.solution.category, index:dilemma.solution.index});            
                    } else {
                        setDisableNext(true);
                    }
                } else {
                    if (haveUseful) {
                        setDisableNext(false);
                    } else {
                        setDisableNext(true);
                    }
                }
            } else {
                setDisableNext(true);
            }
    }, [cats, dilemma]);

    if (chooseEnabled) {
        var topTitle =
            <>
                <div className="title1">
                    <img src={ThinkplanTitle1}  style={{height:'100%'}} />
                </div>
                <div className="title2">
                    <img src={ThinkplanTitle5}  style={{height:'100%'}} />
                </div>
            </>;
    } else {
        topTitle = 
            <>
                <div className="title1">
                    <img src={ThinkplanTitle1}  style={{height:'100%'}} />
                </div>
                <div className="title2">
                    <img src={ThinkplanTitle4}  style={{height:'100%'}} />
                </div>
            </>;
    }
   

    const handleBack = () => {
        if (chooseEnabled) {
            if (dilemma) {
                if (dilemma.solution) {
                    dispatch(deselectBubble(dilemma.solution.category, dilemma.solution.index));
                }
            }
           // setChooseEnabled(false);
           dispatch(setChooseEnebled(false));
        } else {
            navigate('/thinkplan');
        }
        dispatch(clearSolution());
    }

    const handleNext = () => {
      //  console.log("handleNext", chooseEnabled, dilemma.solution);
        if (chooseEnabled) {
            navigate('/try');
           /// setModalInfo('Are you sure you want to select this solution?');
        } else {
            setDisableNext(true);
           // setChooseEnabled(true);
           dispatch(setChooseEnebled(true));
        }
    }

    const onDrop = (qIndex, item, destIndex, newIndex) => {
      //  console.log("onDrop", qIndex, item.category, destIndex, newIndex);
        if (item.category >= 0) {
            if (item.category != qIndex) {
                dispatch(replaceCategory(item.category, qIndex, item.index, newIndex));
            } else {
             //   console.log("dispatch reorder",sourceCat, oldIndex, destNewIndex, item.text)
                if (sourceCat > 0) {
                    dispatch(reorderCategory(sourceCat, oldIndex, destNewIndex, item.index));
                    dispatch(setMoveIndexes(-1, -1, -1, -1));
                }
            }
        } else {
//            dispatch(addToCategoryFromTop(0, qIndex, destIndex));
            dispatch(addToCategoryFromTop(0, qIndex, newIndex));
        }

    }

    const unDrop = () => {
       // console.log("unDrop");
    }


    const onBubbleClick = (index) => {
       // console.log("onBubbleClick", index);
        setShowInput({show:true, index:index});
    }

    const onCatBubbleDbClick = (category, index) => {
      //  console.log("onCatBubbleDbClick", category, index);
        setShowCategoryInput({show:true, index:index, category:category});
    }

    const closeInput = (text, index, category) => {
     //   console.log("closeInput", text, index, category);
        if (index >= 0) {
            dispatch(changeAnswer(index, text, category));
            if (category >=0) {
                setShowCategoryInput({show:false, index:-1});
            } else {
                setShowInput({show:false, index:-1});
            }
        } else {
            setShowInput({show:false, index:-1});
            setShowCategoryInput({show:false, index:-1});
        }
    }

    var answerTop = null;
    if (currentAnswer) {
        answerTop = <AnswerBubbleDrag text={currentAnswer.text} index={0} onBubbleClick={onBubbleClick} category={-1}></AnswerBubbleDrag>
    }

    const getGridStyle = () => {
        if (answerTop) {
            return "sectionsGrid1";
        } else {
            return "sectionsGrid";
        }
    }

    const getBubbleTopStyle = () => {
        if (answerTop) {
            return "bubbleTop";
        } else {
            return "bubbleTop1";
        }
    }

    var answerInput = null;
    if (showInput.show) {
        answerInput = 
            <div id="overlay">
                <AnswerInputPage index={showInput.index} category={-1} callback={closeInput}></AnswerInputPage>
            </div>
    }
    if (showCategoryInput.show) {
        answerInput = 
        <div id="overlay">
           <AnswerInputPage index={showCategoryInput.index} category={showCategoryInput.category} callback={closeInput}></AnswerInputPage>
        </div>
    }

    var catDisabled = false;
    if (chooseEnabled) {
        catDisabled = true;
    }

    return (
        <>
        <div className="organizePage">
                <div className="trafficAndTitle">
                    <TrafficLight color={2}>
                    </TrafficLight>
                    <div className="title">
                        {topTitle}
                    </div>
                </div>

            <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend} options={isTouchDevice ? backendOptions : null}>
                <div className={getBubbleTopStyle()}>
                    {answerTop}
                </div>
                <div className={getGridStyle()}>
                    <CategorySection color="red" title="NotHelpful" key={0} qIndex={0} onDrop={onDrop} unDrop={unDrop} disabled={catDisabled} chooseEnabled={chooseEnabled} onCatBubbleDbClick={onCatBubbleDbClick}>
                    </CategorySection>
                    <CategorySection color="yellow" title="KindOfHelpful" key={1} qIndex={1} onDrop={onDrop} unDrop={unDrop} chooseEnabled={chooseEnabled} onCatBubbleDbClick={onCatBubbleDbClick}>
                    </CategorySection>
                    <CategorySection color="green" title="Helpful" key={2} qIndex={2} onDrop={onDrop} unDrop={unDrop} chooseEnabled={chooseEnabled} onCatBubbleDbClick={onCatBubbleDbClick}>
                    </CategorySection>
                </div>
                <CustomDragLayer>

                </CustomDragLayer>

            <div className="bottom">
                <BackButton text="Back" callback={handleBack}/>
                <NextButton text="Next" disabled={disableNext} callback={handleNext}/>
            </div>
            </DndProvider>
        </div>
            {answerInput}
            {modalInfo !== false && (
                <ModalPortal 
                    onNo={() => {
                        setModalInfo(false);
                        ///dispatch(deselectBubble(solution.category, solution.index));
                       /// dispatch(deselectBubble());
                        dispatch(clearSolution());
                        //setDelindex(null);
                    }} 
                    onYes={() => {
                       // dispatch(removeAnswer(delindex)); 
                        setModalInfo(false);
                      ///  dispatch(chooseSolution(solution.category, solution.index));
                        navigate('/try');
                    }}
                >
                    {modalInfo}
                </ModalPortal>
            )}
        </>
    )
}

export default OrganizePage;

