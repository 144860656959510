import React from "react";
import ButtonText from '../components/ButtonText';
import NextSign from '../assets/next-sign.png';

export default function NextButton(props) {
    const { text, disabled, callback } = props;
    var imgStyle = {height:'35px', marginLeft:'10px', opacity: 1};
   // console.log(disabled);
    if (disabled) {
        imgStyle = {height:'35px', marginLeft:'10px', opacity: 0.3};
    }
    return (
        <ButtonText className="nav thinkplan" disabled={disabled} onClick={callback}>
            {text}<img src={NextSign} style={imgStyle}/>
        </ButtonText> 
    )
}