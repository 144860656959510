import React, {useEffect, useState} from "react";
import ButtonText from '../components/ButtonText';

const AnswerBubble = ({text, index, onClose, onBubbleClick}) => {    
    const [waitingClick, setWaitingClick] = useState(null);
    const [lastClick, setLastClick] = useState(0);

    const processClick = (e) => {
        if(lastClick&&e.timeStamp - lastClick < 250 && waitingClick) {
          setLastClick(0);
          clearTimeout(waitingClick);
          setWaitingClick(null);
          //console.log('double click')
          onBubbleClick(index);
        } else {
            setLastClick(e.timeStamp);
            setWaitingClick(setTimeout(()=>{
            setWaitingClick(null);
            }, 251))
           // console.log('single click')
          }
          
    }

    const onCloseClick = () => {
        onClose(index);
    }
    var btnClose = null;
    if (onClose && (text.length > 0)) {
        btnClose =  <ButtonText className="close" onClick={onCloseClick}>
                        <b>x</b> 
                    </ButtonText>
    }


    return (
        <div className="answerBubble">
            <div className="bubble" onClick={processClick}>
                {text}
            </div>
            {btnClose}
         {/*   <ButtonText className="close" onClick={onCloseClick}>
               <b>x</b> 
            </ButtonText> */}
        </div>
    )
}

export default AnswerBubble;