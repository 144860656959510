import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate  } from 'react-router-dom';
import BackButton from '../components/BackButton';
import NextButton from '../components/NextButton';
import TrafficLight from '../components/TrafficLight';
import ThinkplanTitle1 from '../assets/thinkplan-title1.png';
import ThinkplanTitle3 from '../assets/thinkplan-title3.png';

const AnswerInputPage = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [answer, setAnswer] = useState("");
    const textInput1 = useRef(null);
    const { callback, index, category } = props;
    const answersArr = useSelector(state => state.answers);
    const cats = useSelector(state => state.cats);

    useEffect(() => {
        textInput1.current.focus();
        if (index >= 0) {
            if (category >= 0) {
               // console.log(cats[category][index].text);
                setAnswer(cats[category][index].text);
            } else {
              //  console.log(answersArr[index].text);
                setAnswer(answersArr[index].text);
            }
        }
    }, [])

    const handleDone = () => {
        callback(answer, index, category);
    }

    const handleChange = (e) => {
        setAnswer(e.target.value);
    }

    const handleBack = () => {
        callback("", -2, -1);
    }

    var disable = true;
    if (answer.length > 5) {
        disable = false;
    }

    return (
            <div className="AnswerInput">
                <div className="trafficAndTitle">
                    <TrafficLight color={2}>
                    </TrafficLight>
                    <div className="title">
                        <div className="title1">
                            <img src={ThinkplanTitle1}  style={{height:'100%'}} />
                        </div>
                        <div className="title2">
                        <img src={ThinkplanTitle3}  style={{height:'100%'}} />
                        </div>
                    </div>
                </div>
                <textarea
                    type="textarea"
                    id="inputAnswer" 
                    name="answer"
                    ref={textInput1}
                    placeholder=""
                    value={answer}
                    wrap={"true"}
                    onChange={(e) => {handleChange(e);}}
                />
                <div className="bottom">
                    <BackButton text="Back" callback={handleBack}/>
                    <NextButton text="Add" disabled={disable} callback={handleDone}/>
                   {/* <ButtonText className="nav thinkplan" onClick={handleBack}>
                        Back
                    </ButtonText> 
                    <ButtonText className="nav thinkplan" disabled={disable} onClick={handleDone}>
                        Done
    </ButtonText> */}
                </div>
        </div>
    )
}

export default AnswerInputPage;