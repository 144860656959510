import React from "react";

export default function ButtonText(props) {
    const { children, disabled } = props;

    const newProps = {...props}

    delete newProps.loading
    delete newProps.children

    return (
        <button {...newProps} disabled={disabled}>
                <>{children}</>
        </button>
    );
}
