import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setAnswersPos } from "../redux/actions/";

function Dragfree({ children, index, zIndex, maxZ, style, className, onDragStart, onDragEnd }) {
    const dragRef = useRef(null);
    const dispatch = useDispatch();
    const stw = 1674;
    const sth = 900;
    let isMouseDown = false;
    let offset = [0, 0];

    const transl = useSelector((state => state.translParams));
    const answersArr = useSelector(state => state.answers);
    const [refresh, setRefresh] = useState(false);

    const onMouseDown = (e) => {
      isMouseDown = true;
      const dragDiv = dragRef.current;
      if (!dragDiv) return;
     // console.log("maxZ",answersArr[0].maxZ);
      dragDiv.style.zIndex = answersArr[0].maxZ+1;
      ///dispatch(setAnswersPos(index, dragRef.current.style.left,dragRef.current.style.top));
      const isTouch = /touch/g.test(e.type);
      const x = isTouch ? e.touches[0].clientX : e.clientX;
      const y = isTouch ? e.touches[0].clientY : e.clientY;
  
      offset = [
        dragDiv.offsetLeft - (x/transl.r-transl.w/transl.r),
        dragDiv.offsetTop - (y/transl.r-transl.h/transl.r)
      ];
  
      if (onDragStart) {
        const rect = dragDiv?.getBoundingClientRect();
        onDragStart(rect);
      }
  
      dragDiv.addEventListener('mouseup', onMouseUp, true);
      dragDiv.addEventListener('touchend', onMouseUp, true);
  
      document.addEventListener('contextmenu', onContextMenu, false);
      document.addEventListener('touchmove', onMouseMove, true);
      document.addEventListener('mousemove', onMouseMove, true);
    }
  
    const onMouseUp = () => {
      isMouseDown = false;
      const dragDiv = dragRef.current;
    ///  console.log("AAA",answersArr[0].maxZ);
     /// let mz = maxZ+1;
      dispatch(setAnswersPos(index, answersArr[0].maxZ+1, dragRef.current.style.left,dragRef.current.style.top));
      if (!isMouseDown && onDragEnd) {
        const rect = dragRef.current?.getBoundingClientRect();
        onDragEnd(rect)
      }
  
      document.removeEventListener('touchmove', onMouseMove, true);
      document.removeEventListener('mousemove', onMouseMove, true);
      document.removeEventListener('contextmenu', onContextMenu, false);
    }
  
    const onMouseMove = useCallback((e) => {
      const isTouch = /touch/g.test(e.type);
  
      if (!isTouch) {
        e.preventDefault();
      }
  
      if (isMouseDown && dragRef.current) {
        const x = isTouch ? e.touches[0].clientX : e.clientX;
        const y = isTouch ? e.touches[0].clientY : e.clientY;
  
       // dragRef.current.style.left = (x + offset[0]) + 'px';
        //dragRef.current.style.top = (y + offset[1]) + 'px';
        let stl = (x/transl.r-transl.w/transl.r + offset[0]);
        let stt = (y/transl.r-transl.h/transl.r + offset[1])
        if (stl >= (stw-310)) {
            stl = stw-310;
        }
        if (stl <= 10) {
            stl = 10;
        }
        if (stt >= (sth-130)) {
            stt = sth-130;
        }
        if (stt <= 10) {
            stt = 10;
        }
        dragRef.current.style.left = stl + 'px';
        dragRef.current.style.top = stt + 'px';
       // console.log(dragRef.current.style.left, dragRef.current.style.top);
      }
    }, []);
  
    const onContextMenu = () => {
      document.removeEventListener('mousemove', onMouseMove, true);
      document.removeEventListener('touchmove', onMouseMove, true);
    }
  
    useEffect(() => {
     /// console.log("maxZ",index,maxZ);
      const dragDiv = dragRef.current;
      dragDiv.style.zIndex = zIndex;
      dragDiv?.addEventListener('touchstart', onMouseDown, true);
      dragDiv?.addEventListener('mousedown', onMouseDown, true);
  
      return () => {
        dragDiv?.removeEventListener('mousedown', onMouseDown, true);
        dragDiv?.removeEventListener('mouseup', onMouseUp, true);
        document.removeEventListener('mousemove', onMouseMove, true);
  
        dragDiv?.removeEventListener('touchstart', onMouseDown, true);
        dragDiv?.removeEventListener('touchend', onMouseUp, true);
        document.removeEventListener('touchmove', onMouseMove, true);
  
        document.removeEventListener('contextmenu', onContextMenu, false);
      }
    }, []);
  
   /* useEffect(() => {
      console.log("CCC");
     /// const dragDiv = dragRef.current;
     /// dragDiv.style.zIndex = answersArr[index].zz;
    }, [answersArr]);*/

    return <div
      ref={dragRef}
      className={className || "drag-react"}
      style={{ position: 'fixed', left: '10px', top: '10px', cursor: 'move', ...style }}
    >{children}</div>
  }
 
  export default Dragfree;