import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { changeBg, newDilemma } from "../redux/actions/";
import TrafficLight from '../components/TrafficLight';
import BackButton from '../components/BackButton';
import NextButton from '../components/NextButton';
import TryTitle1 from '../assets/try-title1.png';
import TryTitle2 from '../assets/try-title2.png';

const TryPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dilemmaValue = useSelector(state => state.dilemma);
    const cats = useSelector(state => state.cats);
    const [dilemma, setDilemma] = useState(null);
    const [solution, setSolution] = useState("");

    const handleBack = () => {
        navigate("/organize");
    }
    const handleSave = () => {

    }
    
    const handleNew = () => {
        dispatch(newDilemma());
        navigate("/recognize");
    }
 
    useEffect(() => {
        dispatch(changeBg('yellow'));
    },[]);

   /* useEffect(() => {
        setDilemma(dilemmaValue);
      // console.log(dilemmaValue);
    },[dilemmaValue]);*/
//console.log(dilemmaValue);
    return (
        <div className="tryPage">
            <div className="trafficAndTitle">
                <TrafficLight color={3}>
                </TrafficLight>
                <div className="title">
                    <div className="title1">
                        <img src={TryTitle1}  style={{height:'100%'}} />
                    </div>
                    <div className="title2">
                        <img src={TryTitle2}  style={{height:'100%'}} />
                    </div>
                </div>
            </div>
            <div className="dilemma shadow">
                <div className="dilemmaTitle">
                    <b>Dilemma:</b>
                </div>
                <div className="dilemmaText">
                    <b>{dilemmaValue ? dilemmaValue.text : null}</b>
                </div>
            </div>
            <div className="solution shadow">
                <div className="solutionTitle">
                    <b>Solution to try:</b>
                </div>
                <div className="solutionText">
                    <b>
                        {(dilemmaValue.solution ? dilemmaValue.solution.text : "")}
                    </b>
                 </div>
            </div>
            <div className="bottom">
                <BackButton text="Back" callback={handleBack}/>
                <NextButton text="New" callback={handleNew}/>

             {/*   <ButtonText className="nav thinkplan" onClick={handleBack}>
                    Back
                </ButtonText> 
                <ButtonText className="nav thinkplan" disabled={true} onClick={handleSave} >
                    Save
                </ButtonText> 
                <ButtonText className="nav thinkplan" onClick={handleNew} >
                    New
               </ButtonText> */}
            </div>
        </div>
    )
}

export default TryPage;