import React, {memo} from "react";

export const AnswerBubbleDragPreview = memo(function({text, index, category, onClose, selectSolution }) {

    var cl = "bubble";
      return (
        <div className="answerBubblePreview">
            <div className={cl} >
                {text}
            </div>
            {/*btnClose*/} 
        </div> 
    )
})
