import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setDilemma } from "../redux/actions/";
import TrafficLight from '../components/TrafficLight';
import BackButton from '../components/BackButton';
import NextButton from '../components/NextButton';
import StopTitleImg from '../assets/stop-title.png';
import RecognizeTitleImg from '../assets/recognize-title.png';
import { changeBg } from '../redux/actions';

const RecognizePage = () => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState("");
    const [disableNext, setDisableNext] = useState(true);
    const dispatch = useDispatch();
    const dilemmaValue = useSelector(state => state.dilemma);

    const textInput = useRef(null);
    
    useEffect(() => {
        textInput.current.focus();
    }, [])

    useEffect(() => {
        if (dilemmaValue.text) {
            if (dilemmaValue.text.length > 5) {
               // console.log(dilemmaValue);
                setAnswer(dilemmaValue.text);
                setDisableNext(false);
            } else {
                setDisableNext(true);
            }
        }
    }, [dilemmaValue]);

    useEffect(() => {
        dispatch(changeBg('yellow'));
    },[])

    const handleBack = () => {
        navigate('/start');
    }

    const handleNext = () => {
        dispatch(setDilemma(answer));
        navigate('/thinkplan');
    }

    const handleChange = (e) => {
        if (e.target.value.length >= 5) {
            setDisableNext(false);
        } else {
            setDisableNext(true);
        }
        setAnswer(e.target.value);
    }

    return(
        <div className="RecognizePage">
            <div className="trafficAndTitle">
                <TrafficLight color={1}>
                </TrafficLight>
                <div className="title">
                    <div className="title1">
                        <img src={StopTitleImg}  style={{height:'100%'}} />
                    </div>
                    <div className="title2">
                        <img src={RecognizeTitleImg}  style={{height:'100%'}} />
                    </div>
                </div>
            </div>
            <div className="TextAreaOut">
                <textarea
                        type="textarea"
                        id="inputDilemma" 
                        name="dilemma"
                        ref={textInput}
                        placeholder=""
                        value={answer}
                        wrap={"true"}
                        onChange={(e) => {handleChange(e);}}
                    />
            </div>
            <div className="bottom">
                <BackButton text="Back" callback={handleBack}/>
                <NextButton text="Next" disabled={disableNext} callback={handleNext}/>
            </div>
        </div>
    )
}

export default RecognizePage;