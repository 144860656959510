import React from 'react';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import StartPage from './pages/StartPage';
import RecognizePage from './pages/RecognizePage';
import ThinkPlanPage from './pages/ThinkPlanPage';
import OrganizePage from './pages/OrganizePage';
import TryPage from './pages/TryPage';
import AnswerInputPage from './pages/AnswerInputPage';
import Frame from 'react-frame-component';

const { default: LayoutGeneral } = require('./layouts/general');
const initialContent = `<!DOCTYPE html><html><head>${document.head.innerHTML}</head><body><div></div></body></html>`;

const styles = {
  width: '100%',
  height: '100%',
//width: '1674px',
//height: '900px',
};

function App() {
  return (
    <Router forceRefresh={true} basename="">
        <LayoutGeneral>
            <Routes>
              <Route exact path="/start" element={<StartPage/>} />
              <Route exact path="/recognize" element={<RecognizePage/>} />
              <Route exact path="/thinkplan" element={<ThinkPlanPage/>} />
              <Route exact path="/organize" element={<OrganizePage/>} />
              <Route exact path="/try" element={<TryPage/>} />
              <Route exact path="/answerinput" element={<AnswerInputPage/>} />
            </Routes>
          </LayoutGeneral>
    </Router>
  );
}

export default App;
