import React from 'react';
import ReactDOM from 'react-dom';

const ModalYesNo = ({children, onNo, onYes, button_label1 = 'Yes', button_label2 = 'No'}) => {
    return(
        <div id="modalWrap">
            <div id="modalContent">
                <div id="modalBody">
                    <p>{children}</p>
                    <div className="yesnobtns">
                        <button className="btnyes" onClick={onYes}>
                            <b>{button_label1}</b>
                        </button>
                        <button className="btnno" onClick={onNo}>
                        <b>{button_label2}</b>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function ModalPortal({children, onNo, onYes, title = '', button_label1 = 'Yes', button_label2 = 'No'}) {
    return ReactDOM.createPortal(
        <ModalYesNo onYes={onYes} onNo={onNo} title={title} button_label1={button_label1} button_label2={button_label2}>
            {children}
        </ModalYesNo>,
        document.getElementById('root')
    );
}
