import { 
    SET_DILEMMA, 
    ADD_ANSWER, 
    REMOVE_ANSWER, 
    CHANGE_ANSWER, 
    ADD_TO_CATEGORY_FROM_TOP, 
    REPLACE_CATEGORY, 
    REMOVE_FROM_CATEGORY,
    SELECT_BUBBLE,
    DESELECT_BUBBLE,
    CHOOSE_SOULTION,
    NEW_DILEMMA,
    INSERT_DUMMY_BUBBLE,
    REMOVE_TEMP_BUBBLE,
    REORDER_CATEGORY,
    CHANGE_BUBBLE_XY,
    CLEAR_SOLUTION,
    CHANGE_BG,
    SET_CHOOSE_ENABLED,
    SET_MOVE_INDEXES,
    SET_TRANS_PARAMS,
    SET_ANSWERS_POS
} from '../types';

const initialState = {
    translParams: {r:0, w:0, h:0},
    sourceCat: -1, 
    oldIndex:-1, 
    newIndex:-1,
    index:-1,
    dilemma: {},
    answers: [],
    cats: [[],[],[]],
    positions: [
        [0,0,0,0,0],
        [0,0,0,0],
        [0,0,0,0],
        [0,0,0,0,0],
        [0,0]
    ],
    bg: 'yellow',
    chooseEnabled: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DILEMMA:
          //  console.log("reducer SET_DILEMMA");
            return {
                ...state,
                dilemma: {text: action.text, solution: null}
            };
        case ADD_ANSWER:
         //   console.log("reducer ADD_ANSWER");
         //   console.log(state);
            var obj = {
                text: action.text, 
                category: -1, 
                selected: false, 
                xx: action.xx,
                yy: action.yy,
                zz: state.answers.length,
                rind: action.rind,
                pind: action.pind,
                id: Date.now()
            };
            var parr = [...state.positions];
            if ((action.rind > -1) && (action.pind > -1)) {
                parr[action.rind][action.pind] = 1;
            }
          //  console.log("ADD_ANSWER", obj);
            var arr = [...state.answers];
            ///var arr = state.answers.concat([obj]);
            arr = arr.concat([obj]);
            for (var i=0;i<arr.length;i++) {
                arr[i].maxZ = arr.length-1;
            }
            return {
                ...state, answers: arr, positions: parr
             };
        case REMOVE_ANSWER:
          ///  console.log("reducer REMOVE_ANSWER");
         //   console.log(state);
            arr = [...state.answers];
            parr = [...state.positions];
            var rind = arr[action.index].rind;
            var pind = arr[action.index].pind;
            ///console.log(rind, pind);
            if ((rind != -1) && (pind != -1)) {
                parr[rind][pind] = 0;
            }
            arr.splice(action.index, 1);
            return {
                ...state, answers: arr, positions: parr
                };
        case CHANGE_ANSWER:
         //   console.log("reducer CHANGE_ANSWER", action.text, action.src, action.index);
         //   console.log(state);
            if (action.src >=0 ) {
                arr = [...state.cats];
                arr[action.src][action.index].text = action.text;
                return {...state, cats: arr}
            } else {
                arr = [...state.answers];
                arr[action.index].text = action.text;
                return {...state, answers: arr};
            }
        case ADD_TO_CATEGORY_FROM_TOP:
         //   console.log("reducer ADD_TO_CATEGORY_FROM_TOP",action.newIndex, state.answers);
            obj = {...state.answers[action.srcindex]};
            obj.category = action.dest;
            arr = [...state.answers];
            parr = [...state.positions];
            var rind = arr[action.srcindex].rind;
            var pind = arr[action.srcindex].pind;
           // console.log(rind, pind);
            if ((rind != -1) && (pind != -1)) {
                parr[rind][pind] = 0;
            }
            arr.splice(action.srcindex, 1);
            var arr2 = [...state.cats];
            if (action.newIndex >= 0) {
                arr2[action.dest].splice(action.newIndex,0,obj);
            } else {
                arr2[action.dest].push(obj);
            }
           return {...state, answers: arr, positions: parr, cats: arr2};
        case REPLACE_CATEGORY:
         //   console.log("reducer REPLACE_CATEGORY", action.src, action.dest, action.index, action.newIndex);
            obj = {...state.cats[action.src][action.index]};
            obj.category = action.dest;
            var arrsrc = [...state.cats[action.src]];
            arrsrc.splice(action.index,1);
           if (action.newIndex >= 0) {
                if (action.src != action.dest) {
                    var arrdest = [...state.cats[action.dest]];
                    arrdest.splice(action.newIndex, 0, obj);
                } else {
                    arrdest = state.cats[action.dest];
                }
           } else {
                if (action.src != action.dest) {
                    var arrdest = state.cats[action.dest].concat([obj]);
                } else {
                    arrdest = state.cats[action.dest];
                }
           }
            arr2 = [];
            for(var i=0;i<state.cats.length;i++) {
                if (i == action.dest) {
                    arr2.push(arrdest);
                } else if (i == action.src) {
                    arr2.push(arrsrc);
                } else {
                    arr2.push(state.cats[i]);
                }
            }
            return {...state, cats: arr2};
        case REMOVE_FROM_CATEGORY:
         //   console.log("reducer REMOVE_FROM_CATEGORY",action.src,action.index);
           /// var obj = {text: state.cats[action.src][action.index].text, category: -1, selected: false, id: state.cats[action.src][action.index].id};
           // obj = {...state.cats[action.src][action.index]};
            //obj.category = -1;
            //obj.selected = false;
            //arr = state.answers.concat([obj]);
            arr2 = [...state.cats];
            obj = arr2[action.src].splice(action.index,1);
            dilemma = {...state.dilemma};
            if (dilemma.solution) {
                if ((dilemma.solution.index == action.index)&&(dilemma.solution.category == action.src)) {
                    dilemma.solution = null;
                }
            }
           // return {...state, answers: arr, cats: arr2};
           return {...state, dilemma: dilemma, cats: arr2};
        case SELECT_BUBBLE:
         //   console.log("reducer SELECT_BUBBLE",action.src,action.index);
            var dilemma = {...state.dilemma};
            arr = [];
            for(var i=0;i<state.cats.length;i++) {
                var arr2 = [];
                for(var j=0;j<state.cats[i].length;j++) {
                    if ((i==action.src) && (j==action.index)) {
                        obj = {...state.cats[i][j]};
                        var obj1 = {...state.cats[i][j]};
                        obj.selected = true;
                        dilemma.solution = obj;
                        arr2.push(obj);
                    } else {
                        obj = {...state.cats[i][j]};
                        obj.selected = false;
                        arr2.push(obj);
                    }
                }
                arr.push(arr2);
            }
            return {...state, dilemma: dilemma, cats: arr}
        case DESELECT_BUBBLE:
            dilemma = {...state.dilemma};
            if (dilemma.solution) {
                dilemma.solution.selected = false;
            }
          ///  dilemma.solution = null;
          arr = [...state.cats];
          if (arr[action.src][action.index]) {
            arr[action.src][action.index].selected = false;
          }
         /*   arr = [];
            for(var i=0;i<state.cats.length;i++) {
                var arr2 = [];
                for(var j=0;j<state.cats[i].length;j++) {
                    if (state.cats[i][j]) {
                        obj = {...state.cats[i][j]};
                        obj.selected = false;
                        arr2.push(obj);
                    }
                }
                arr.push(arr2);
            }*/
            return {...state, dilemma: dilemma, cats: arr}
        case CHOOSE_SOULTION:
         //   console.log("reducer CHOOSE_SOULTION");
            obj = {text: state.dilemma.text, solution: {src: action.src, index: action.index}};
            return {...state, dilemma: obj};
        case NEW_DILEMMA:
            return {...state, dilemma: {},
                answers: [],
                cats: [[],[],[]],
                cat1:[],
                cat2:[],
                cat3:[]
            };
        case INSERT_DUMMY_BUBBLE:
         //   console.log("reducer INSERT_DUMMY_BUBBLE",action.src,action.index);
            obj = {text: "", category: action.src, selected: false, temp:1, id: Date.now()};
            arr = [...state.cats];
            var rj = -1;
            var ri = -1;
            for (var i=0;i<arr.length;i++) {
                for (var j=0;j<arr[i].length;j++) {
                    if(arr[i][j].temp) {
                        ri = i;
                        rj = j;
                        break;
                    }
                }    
            }
            if ((ri>=0)&&(rj>=0)) {
              //  console.log(ri, rj, arr);
                arr[ri].splice(rj,1);
            }

            if (arr[action.src][action.index]) {
                arr[action.src].splice(action.index,0,obj);
            } else {
               // console.log("reducer INSERT_DUMMY_BUBBLE B");
                arr[action.src].push(obj); 
            }
            return {...state, cats: arr};
        case REMOVE_TEMP_BUBBLE:
          //  console.log("reducer REMOVE_TEMP_BUBBLE");
            arr = [...state.cats];
            var rj = -1;
            var ri = -1;
            if (!action.src) {
                for (var i=0;i<arr.length;i++) {
                    for (var j=0;j<arr[i].length;j++) {
                        if(arr[i][j].temp) {
                            ri = i;
                            rj = j;
                            break;
                        }
                    }    
                }
            } else {
                for (var j=0;j<arr[action.src].length;j++) {
                    if(arr[action.src][j].temp) {
                        ri = action.src;
                        rj = j;
                        break;
                    }
                }    
            }
            if ((ri>=0)&&(rj>=0)) {
              //  console.log(ri, rj, arr);
                arr[ri].splice(rj,1);
            }
            return {...state, cats: arr};
        case REORDER_CATEGORY:
          //  console.log("dispatch reducer REORDER_CATEGORY", action.oldIndex, action.newIndex, action.index);
            arr = [...state.cats];
            var arr1 = arr[action.src];
           /// if (arr1.length > 1) {
            if (arr1[action.newIndex] == undefined) {
              //  console.log("UNDEFINED1")
            } else {
                if (arr1[action.newIndex].text === "") {
                 //   console.log("dispatch reorder1", arr1[action.index+1].text, arr1[action.newIndex].text, arr1[action.oldIndex].text);
                    if (action.newIndex==0) {
                      //  console.log("dispatch reorder2");
                        var howerObj = arr1[action.newIndex];
                        var dragObj = arr1[action.index+1];
                        arr1[action.newIndex] = dragObj;
                        arr1[action.index+1] = howerObj;
                    } else if (action.newIndex == (arr1.length-1)) {
                      //  console.log("dispatch reorder3");
                        var howerObj = arr1[action.index];
                        var dragObj = arr1[action.newIndex];
                        arr1[action.index] = dragObj;
                        arr1[action.newIndex] = howerObj;
                    } else {
                        howerObj = arr1[action.oldIndex];
                        dragObj = arr1[action.index+1];
                        arr1[action.oldIndex] = dragObj;
                        arr1[action.index+1] = howerObj;
                    }
                } else {
                    howerObj = arr1[action.newIndex];
                  //  console.log(howerObj.text)
                    dragObj = arr1[action.oldIndex];
                   // console.log(dragObj.text)
                    arr1[action.newIndex] = dragObj;
                    arr1[action.oldIndex] = howerObj;
                }
            }
             arr2 = [];  
             for (var i=0;i<arr1.length;i++) {
                if (arr1[i] != undefined) {
                    arr1[i].index = i;
                     arr2.push(arr1[i]);
                } else {
                   // console.log("UNDEFINED2",i)
                }
             }
             arr1 = arr2;
            return {...state, cats: arr};
           /// } else {
           ///     return {...state};
           /// }    
        case CHANGE_BUBBLE_XY:
          //  console.log("reducer CHANGE_BUBBLE_XY", action.index, action.xx, action.yy);
            arr  = [...state.answers];
            arr[action.index].xx = action.xx;
            arr[action.index].yy = action.yy;
            return {...state, answers: arr};
        case CLEAR_SOLUTION:
            arr = [];
            for(var i=0;i<state.cats.length;i++) {
                arr2 = [];
                for(var j=0;j<state.cats[i].length;j++) {
                    if (state.cats[i][j]) {
                        obj = {...state.cats[i][j]};
                        obj.selected = false;
                        arr2.push(obj);
                    }
                }
                arr.push(arr2);
            }
            arr1 = {...state.dilemma};
            arr1.solution = null;
            return {...state, dilemma: arr1, cats: arr};
        case SET_MOVE_INDEXES:
                return {...state, sourceCat:action.sourceCat, oldIndex:action.oldIndex, newIndex:action.newIndex, index:action.index}
        case CHANGE_BG:
            if (state.bg != action.bg) {
                return {...state, bg: action.bg}
            } 
            return {...state};
        case SET_CHOOSE_ENABLED:
            return {...state, chooseEnabled: action.fl};
        case SET_TRANS_PARAMS:
            return {...state, translParams: action.obj}
        case SET_ANSWERS_POS:
            //console.log("reducer",SET_ANSWERS_POS);
            arr = [...state.answers];
            var currInd = action.ind;
            var tempInd = 0;
            for(var i=0;i<arr.length;i++) {
                if (i != currInd) {
                    //console.log("BBB");
                    arr[i].zz = tempInd;
                    arr[i].maxZ = action.maxZ;
                    tempInd++;
                }
            }
            arr[currInd].maxZ = action.maxZ;
            arr[currInd].zz = action.maxZ;
            arr[currInd].xx = action.xx;
            arr[currInd].yy = action.yy;
            return {
                ...state, answers: arr
             };
        default: {
            return state;
        }
    }
    
};
