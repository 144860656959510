import { useDragLayer } from 'react-dnd'
import { useDispatch, useSelector } from "react-redux";
import  { AnswerBubbleDragPreview } from './AnswerBubbleDragPreview';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 19999,
    backgroundColor: 'transparent',
    opacity:1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow:'hidden',
}

function getItemStyles(item, transl, initialOffset, currentOffset, isSnapToGrid) {
  //console.log("coord left", item.top);
   /* if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      }
    } */
   if (currentOffset) {
    let { x, y } = currentOffset
   // console.log("coord",x,y,transl);
    const transform = `translate(${x/transl.r-transl.w/transl.r}px, ${y/transl.r-transl.h/transl.r}px)`
    return {
      transform,
      WebkitTransform: transform,
    }
    } else {
      return {
      }  
    }
  }
    
const CustomDragLayer = (props) => {

    const translParams = useSelector((state => state.translParams));

    const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints);

    const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
    ///  currentOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
    }))
    function renderItem() {
      if (!isTouchDevice) {
           //return null;
        }
      //  console.log("itemType", itemType);
        switch (itemType) {
            case "box":
              return <AnswerBubbleDragPreview text={item.text}/>
            default:
              return null
          }
    }
    if (!isDragging) {
        return null
    }
    return(
        <div style={layerStyles}>
          <div
              style={getItemStyles(item, translParams, initialOffset, currentOffset, props.snapToGrid)}
          >
            {renderItem()}
        </div>
        </div>
    )
}

export default CustomDragLayer;