import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate  } from 'react-router-dom';
import { changeBg, addAnswer, removeAnswer, changeAnswer, changeBubbleXY } from "../redux/actions/";
import TrafficLight from '../components/TrafficLight';
import ButtonImage from '../components/ButtonImage';
import AnswerBubble from '../components/AnswerBubble';
import AnswerInputPage from './AnswerInputPage';
/// import { Draggable } from 'drag-react';
import Drfagfree from '../components/Dragfree';
import ModalPortal from '../components/ModalYesNo';
import BackButton from '../components/BackButton';
import NextButton from '../components/NextButton';
import ThinkplanTitle1 from '../assets/thinkplan-title1.png';
import ThinkplanTitle2 from '../assets/thinkplan-title2.png';
import ThinkplanTitle6 from '../assets/thinkplan-title6.png';
import PlusSign from '../assets/plus-sign.png';

const ThinkPlanPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const answersArr = useSelector(state => state.answers);
    const catsArr = useSelector(state => state.cats);
    const pos = useSelector(state => state.positions);
    const trp = useSelector((state => state.translParams));
    const [refresh, setRefresh] = useState(false);
    const [showInput, setShowInput] = useState({show:false, index:null});
    const [answerRender, setAnswersRender] = useState([]);
    const [modalInfo, setModalInfo] = useState(false);
    const [delindex, setDelindex] = useState(null);

    const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints);
    const stw = 1674;
    const sth = 900;
    const bubW = 300;
    const bubH = 120;
    const bubM = 8;

    const refreshComponent = () => {
        setRefresh(!refresh);
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', refreshComponent);
        return () => {
          window.removeEventListener('resize', refreshComponent);
        }
      })
      
    useEffect(() => {
     /// console.log("answersArr ThinkPlan",answersArr);
        let tempArr = [];
        for (let i=0; i<answersArr.length; i++) {
            tempArr.push({
                text:answersArr[i].text, 
                category:answersArr[i].category, 
                key:i, 
                x: answersArr[i].xx, 
                y: answersArr[i].yy,
                z: answersArr[i].zz,
                ypos: answersArr[i].ypos,
                xpos: answersArr[i].xpos,
                maxZ: answersArr[i].maxZ
            });
        }
        setAnswersRender(tempArr);
        //setDelindex(null);
    }, [answersArr]);

    useEffect(() => {
        dispatch(changeBg('yellow'));
    },[])

    const handlePlusClick = () => {
        setShowInput({show:true, index:-1});
    }

    const handleNext = () => {
        navigate('/organize');
    }

    const handleBack = () => {
        navigate('/recognize');
    }

    const onDragEnd = (e, key) => {
      //  console.log(e, key);
        dispatch(changeBubbleXY(key, e.x, e.y));
    }

    const closeInput = (text, index) => {
      //  console.log(text, index);
        if (index >= 0) {
            dispatch(changeAnswer(index, text));
        } else {
            if (index === -1) {
                // select rows with free places
                var freeRowsInds = [];
                for (var i=0;i<pos.length;i++) {
                    if (pos[i].indexOf(0) >= 0) {
                        freeRowsInds.push(i);
                    }
                }
               /// console.log(pos);
               // console.log(freeRowsInds);
                // get random index of row
                var freePosInds = [];
                if (freeRowsInds.length > 0) {
                    var rind = Math.floor(Math.random()*freeRowsInds.length);
                    var ypos = freeRowsInds[rind];
                  //  console.log("rind", ypos);
                  //  console.log(pos[rind]);
                    for (var i=0;i<pos[ypos].length;i++) {
                        if (pos[ypos][i] != 1) {
                            freePosInds.push(i);
                        }
                    }
                    if (freePosInds.length > 0) {
                        var pind = Math.floor(Math.random()*freePosInds.length);
                        var xpos = freePosInds[pind];
                       // console.log(freePosInds);
                      //  console.log(pind, xpos);
                    }
                }
                var minw = Math.ceil(stw/2 - 300);
                var maxw = Math.floor(stw/2 + 300);
                var minh = Math.ceil(sth/2 - 100);
                var maxh = Math.floor(sth/2 + 100);
                if ((freePosInds.length > 0) && (freePosInds.length > 0)) {
                    var xofs = 20;
                    if ((ypos == 1)||(ypos == 2)) {
                        xofs = 330;
                    }
                    if (ypos == 4) {
                        xofs = 550;
                    }
                    var xx = xofs + xpos*(bubW + bubM);
                    var yy = 210 + ypos*(bubH + bubM);
                    dispatch(addAnswer(text, xx, yy, ypos, xpos));
                } else {
                    xx = Math.floor(Math.random() * (maxw - minw + 1)) + minw;
                    yy = Math.floor(Math.random() * (maxh - minh + 1)) + minh;
                    dispatch(addAnswer(text, xx, yy, -1, -1));
                }
            }
        }
        setShowInput({show:false, index:-1});
    }

    const onBubbleClose = (index) => {
      //  console.log(index); 
        setModalInfo('Are you sure you want to delete this solution?');
        setDelindex(index);
     //   dispatch(removeAnswer(index));  
    }

    const onBubbleClick = (index) => {
      //  console.log("onBubbleClick", index);
        setShowInput({show:true, index:index});
    }


    var answerInput = null;
    //var dragStyle = {visibility:'visible'}
    if (showInput.show) {
      //  dragStyle = {visibility:'hidden'}
        answerInput = 
            <div id="overlay">
                <AnswerInputPage index={showInput.index} callback={closeInput}></AnswerInputPage>
            </div>
    }

    var disableNext = true;
    if (answersArr.length > 0) {
        disableNext = false;
    } else {
        let fl = false;
        for (let i=0;i<catsArr.length;i++) {
            if (catsArr[i].length > 0) {
                fl = true;
                break;
            }
        }
        if (fl) {
            disableNext = false;
        } else {
            disableNext = true;
        }
    }

     return(
        <>
        {!answerInput && (<div className="ThinkPlanPage">

            <div className="trafficAndTitle">
                <TrafficLight color={2}>
                </TrafficLight>
                <div className="title">
                    <div className="title1">
                        <img src={ThinkplanTitle1}  style={{height:'100%'}} />
                    </div>
                    <div className="title2">
                        {isTouchDevice ? <img src={ThinkplanTitle6}  style={{height:'100%'}} /> :
                            <img src={ThinkplanTitle2}  style={{height:'100%'}} />}
                    </div>
                </div>
            </div>
            <div style={{width: '100%'}}>
              {/*  <ButtonText className="plusButton" onClick={handlePlusClick}>
                    <b>+</b>
                        </ButtonText> */}
                <ButtonImage className="plusButton" onClick={handlePlusClick} >
                    <img src={PlusSign} style={{width: '60px'}} />
                </ButtonImage> 
            </div>

            <div style={{width:'100%', minHeight:'200px'}}>
            {answerRender.map(({text, category, key, x, y, z, ypos, xpos, maxZ}) => {
                    var ind = key;
                    var xx = x;
                    var yy = y;
                    var zz = z;
                    if ((xpos == -1) && (ypos == -1)) {
                        if (xx >= (stw-300)) {
                            xx = stw-300;
                        }
                        if (xx <= 300) {
                            xx = 300;
                        }
                        if (yy >= (sth-100)) {
                            yy = sth-100;
                        }
                        if (yy <= 100) {
                            yy = 100;
                        }
                    }
                    return(
                        <Drfagfree key={key} index={key} maxZ={maxZ} zIndex={zz} style={{ position: 'fixed', left: xx, top: yy, cursor: 'move'}}>   
                          {/* <AnswerBubble text={text} index={key} onClose={onBubbleClose} onBubbleClick={onBubbleClick}></AnswerBubble> */}
                            <AnswerBubble text={text} index={key} onBubbleClick={onBubbleClick}></AnswerBubble>
                        </Drfagfree>
                    )}
                )}
            </div>

            <div className="bottom">
             {/*   <ButtonText className="nav thinkplan" onClick={handleBack}>
                    Back
                </ButtonText> 
                <ButtonText className="nav thinkplan" disabled={disableNext} onClick={handleNext} >
                    Next
                    </ButtonText> */}
                <BackButton text="Back" callback={handleBack}/>
                <NextButton text="Next" disabled={disableNext} callback={handleNext}/>
            </div>
        </div>)}

        {answerInput}

            {modalInfo !== false && (
                <ModalPortal 
                    onNo={() => {
                        setModalInfo(false);
                        setDelindex(null);
                    }} 
                    onYes={() => {
                        dispatch(removeAnswer(delindex)); 
                        setModalInfo(false);
                    }}
                >
                    {modalInfo}
                </ModalPortal>
            )}
        </>
    )
}

export default ThinkPlanPage;