export const SET_DILEMMA = 'SET_DILEMMA';
export const ADD_ANSWER = 'ADD_ANSWER';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';
export const CHANGE_ANSWER = 'CHANGE_ANSWER';
export const ADD_TO_CATEGORY_FROM_TOP = 'ADD_TO_CATEGORY_FROM_TOP';
export const REPLACE_CATEGORY = 'REPLACE_CATEGORY';
export const REMOVE_FROM_CATEGORY = 'REMOVE_FROM_CATEGORY';
export const SELECT_BUBBLE = 'SELECT_BUBBLE';
export const DESELECT_BUBBLE = 'DESELECT_BUBBLE';
export const CHOOSE_SOULTION = 'SET_SOULTION';
export const NEW_DILEMMA = 'NEW_DILEMMA';
export const INSERT_DUMMY_BUBBLE = 'INSERT_DUMMY_BUBBLE';
export const REMOVE_TEMP_BUBBLE = 'REMOVE_TEMP_BUBBLE';
export const REORDER_CATEGORY = 'REORDER_CATEGORY';
export const CHANGE_BUBBLE_XY = 'CHANGE_BUBBLE_XY';
export const CLEAR_SOLUTION = 'CLEAR_SOLUTION';
export const CHANGE_BG = 'CHANGE_BG';
export const SET_CHOOSE_ENABLED = 'SET_CHOOSE_ENABLED';
export const SET_MOVE_INDEXES = 'SET_MOVE_INDEXES';
export const SET_TRANS_PARAMS = "SET_TRANS_PARAMS";
export const SET_ANSWERS_POS = "SET_ANSWERS_POS";